import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// layouts
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// dashboards
const Home = React.lazy(() => import('../pages/dashboards/Home'));

const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

//accents
const Accent = React.lazy(() => import('../pages/accents/'));
const AccentAdd = React.lazy(() => import('../pages/accents/add'));
const AccentEdit = React.lazy(() => import('../pages/accents/edit'));

//agenda
const Agenda = React.lazy(() => import('../pages/agenda/'));
const AgendaAdd = React.lazy(() => import('../pages/agenda/add'));
const AgendaEdit = React.lazy(() => import('../pages/agenda/edit'));

//support chat
const SupportChat = React.lazy(() => import('../pages/support-chat'));

//review tutors
const ListReviewTutors = React.lazy(() => import('../pages/review-tutors'));
const ReviewTutors = React.lazy(() => import('../pages/review-tutors/review'));

//app language
const AppLanguage = React.lazy(() => import('../pages/app-language'));
const AppLanguageAdd = React.lazy(() => import('../pages/app-language/add'));
const AppLanguageEdit = React.lazy(() => import('../pages/app-language/edit'));

//awareness channel
const AwarenessChannel = React.lazy(() => import('../pages/awareness-channel'));
const AwarenessChannelAdd = React.lazy(() => import('../pages/awareness-channel/add'));
const AwarenessChannelEdit = React.lazy(() => import('../pages/awareness-channel/edit'));

//call
const Call = React.lazy(() => import('../pages/call'));
const CallAdd = React.lazy(() => import('../pages/call/add'));
const CallEdit = React.lazy(() => import('../pages/call/edit'));

//category
const Category = React.lazy(() => import('../pages/category'));
const CategoryAdd = React.lazy(() => import('../pages/category/add'));
const CategoryEdit = React.lazy(() => import('../pages/category/edit'));

//certificate type
const CertificateType = React.lazy(() => import('../pages/certificate-type'));
const CertificateTypeAdd = React.lazy(() => import('../pages/certificate-type/add'));
const CertificateTypeEdit = React.lazy(() => import('../pages/certificate-type/edit'));

//confront level
const ConfrontLevel = React.lazy(() => import('../pages/confront-level'));
const ConfrontLevelAdd = React.lazy(() => import('../pages/confront-level/add'));
const ConfrontLevelEdit = React.lazy(() => import('../pages/confront-level/edit'));

//conversation
const Conversation = React.lazy(() => import('../pages/conversation'));

//correction
const Correction = React.lazy(() => import('../pages/correction'));
const CorrectionAdd = React.lazy(() => import('../pages/correction/add'));
const CorrectionEdit = React.lazy(() => import('../pages/correction/edit'));

//country
const Country = React.lazy(() => import('../pages/country'));
const CountryAdd = React.lazy(() => import('../pages/country/add'));
const CountryEdit = React.lazy(() => import('../pages/country/edit'));

//country
const Course = React.lazy(() => import('../pages/courses'));
const CourseAdd = React.lazy(() => import('../pages/courses/add'));
const CourseEdit = React.lazy(() => import('../pages/courses/edit'));

//country
const Fields = React.lazy(() => import('../pages/fields'));
const FieldsAdd = React.lazy(() => import('../pages/fields/add'));
const FieldsEdit = React.lazy(() => import('../pages/fields/edit'));

//focus
const Focus = React.lazy(() => import('../pages/focus'));
const FocusAdd = React.lazy(() => import('../pages/focus/add'));
const FocusEdit = React.lazy(() => import('../pages/focus/edit'));

//interest
const Interests = React.lazy(() => import('../pages/interests'));
const InterestsAdd = React.lazy(() => import('../pages/interests/add'));
const InterestsEdit = React.lazy(() => import('../pages/interests/edit'));

//interest
const Languages = React.lazy(() => import('../pages/languages'));
const LanguagesAdd = React.lazy(() => import('../pages/languages/add'));
const LanguagesEdit = React.lazy(() => import('../pages/languages/edit'));

//lessons
const Lessons = React.lazy(() => import('../pages/lessons'));
const LessonsAdd = React.lazy(() => import('../pages/lessons/add'));
const LessonsEdit = React.lazy(() => import('../pages/lessons/edit'));

//Level
const Level = React.lazy(() => import('../pages/level'));
const LevelAdd = React.lazy(() => import('../pages/level/add'));
const LevelEdit = React.lazy(() => import('../pages/level/edit'));

//Level
const Notification = React.lazy(() => import('../pages/notification-item'));
const NotificationAdd = React.lazy(() => import('../pages/notification-item/add'));
const NotificationEdit = React.lazy(() => import('../pages/notification-item/edit'));

//Period
const Period = React.lazy(() => import('../pages/period'));
const PeriodAdd = React.lazy(() => import('../pages/period/add'));
const PeriodEdit = React.lazy(() => import('../pages/period/edit'));

//Permissions
const Permissions = React.lazy(() => import('../pages/permissions'));
const PermissionAdd = React.lazy(() => import('../pages/permissions/add'));
const PermissionEdit = React.lazy(() => import('../pages/permissions/edit'));

//Plan
const Plan = React.lazy(() => import('../pages/plan'));
const PlanAdd = React.lazy(() => import('../pages/plan/add'));
const PlanEdit = React.lazy(() => import('../pages/plan/edit'));

//Platform
const Platform = React.lazy(() => import('../pages/platform'));
const PlatformAdd = React.lazy(() => import('../pages/platform/add'));
const PlatformEdit = React.lazy(() => import('../pages/platform/edit'));

//Promo Code
const PromoCode = React.lazy(() => import('../pages/promo-code'));
const PromoCodeAdd = React.lazy(() => import('../pages/promo-code/add'));
const PromoCodeEdit = React.lazy(() => import('../pages/promo-code/edit'));

//Reservations
const Reservations = React.lazy(() => import('../pages/reservation'));
const ReservationsAdd = React.lazy(() => import('../pages/reservation/add'));
const ReservationsEdit = React.lazy(() => import('../pages/reservation/edit'));

//Slider
const Slider = React.lazy(() => import('../pages/slider'));
const SliderAdd = React.lazy(() => import('../pages/slider/add'));
const SliderEdit = React.lazy(() => import('../pages/slider/edit'));

//Subscription
const Subscription = React.lazy(() => import('../pages/subscription'));
const SubscriptionAdd = React.lazy(() => import('../pages/subscription/add'));
// const SubscriptionEdit = React.lazy(() => import('../pages/subscription/edit'));

//Support Department
const SupportDepartment = React.lazy(() => import('../pages/support-department'));
const SupportDepartmentAdd = React.lazy(() => import('../pages/support-department/add'));
const SupportDepartmentEdit = React.lazy(() => import('../pages/support-department/edit'));

//Support Ticket
const SupportTicket = React.lazy(() => import('../pages/support-ticket'));
const SupportTicketAdd = React.lazy(() => import('../pages/support-ticket/add'));
const SupportTicketEdit = React.lazy(() => import('../pages/support-ticket/edit'));

//Teaching Style
const TeachingStyle = React.lazy(() => import('../pages/teaching-style'));
const TeachingStyleAdd = React.lazy(() => import('../pages/teaching-style/add'));
const TeachingStyleEdit = React.lazy(() => import('../pages/teaching-style/edit'));

//Teaching Style
const Recordings = React.lazy(() => import('../pages/recordings'));
const RecordingsAdd = React.lazy(() => import('../pages/recordings/add'));
const RecordingsEdit = React.lazy(() => import('../pages/recordings/edit'));

//User
const Tutor = React.lazy(() => import('../pages/tutor'));
const TutorAdd = React.lazy(() => import('../pages/tutor/add'));
const TutorEdit = React.lazy(() => import('../pages/tutor/edit'));

//Tutor Reviews
const TutorReviews = React.lazy(() => import('../pages/tutor-reviews'));

//User
const Student = React.lazy(() => import('../pages/student'));
const StudentAdd = React.lazy(() => import('../pages/student/add'));
const StudentEdit = React.lazy(() => import('../pages/student/edit'));

//Gift Duration
const GiftDuration = React.lazy(() => import('../pages/gift-duration'));
const GiftDurationEdit = React.lazy(() => import('../pages/gift-duration/edit'));

//Widthdraw
const Widthdraw = React.lazy(() => import('../pages/withdraw'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;
        layoutCls = VerticalLayout;
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'login',
                    element: <LoadComponent component={Login} />,
                },
                {
                    path: 'logout',
                    element: <LoadComponent component={Logout} />,
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={Error404} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={Error500} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'admin'} component={Layout} />,
            children: [
                {
                    path: 'home',
                    element: <LoadComponent component={AppLanguage} />,
                },
                {
                    path: 'accent',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Accent} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={AccentAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={AccentEdit} />,
                        },
                    ],
                },
                {
                    path: 'agenda',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Agenda} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={AgendaAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={AgendaEdit} />,
                        },
                    ],
                },
                {
                    path: 'support-chat',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={SupportChat} />,
                        },
                    ],
                },
                {
                    path: 'review-tutors',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={ListReviewTutors} />,
                        },
                        {
                            path: 'review/:id',
                            element: <LoadComponent component={ReviewTutors} />,
                        },
                    ],
                },
                {
                    path: 'app-language',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={AppLanguage} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={AppLanguageAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={AppLanguageEdit} />,
                        },
                    ],
                },
                {
                    path: 'awareness-channel',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={AwarenessChannel} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={AwarenessChannelAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={AwarenessChannelEdit} />,
                        },
                    ],
                },
                {
                    path: 'call',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Call} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={CallAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={CallEdit} />,
                        },
                    ],
                },
                {
                    path: 'category',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Category} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={CategoryAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={CategoryEdit} />,
                        },
                    ],
                },
                {
                    path: 'certificate-type',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={CertificateType} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={CertificateTypeAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={CertificateTypeEdit} />,
                        },
                    ],
                },
                {
                    path: 'confront-level',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={ConfrontLevel} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={ConfrontLevelAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={ConfrontLevelEdit} />,
                        },
                    ],
                },
                {
                    path: 'conversation',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Conversation} />,
                        },
                    ],
                },
                {
                    path: 'correction',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Correction} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={CorrectionAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={CorrectionEdit} />,
                        },
                    ],
                },
                {
                    path: 'country',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Country} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={CountryAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={CountryEdit} />,
                        },
                    ],
                },
                {
                    path: 'course',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Course} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={CourseAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={CourseEdit} />,
                        },
                    ],
                },
                {
                    path: 'fields',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Fields} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={FieldsAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={FieldsEdit} />,
                        },
                    ],
                },
                {
                    path: 'focus',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Focus} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={FocusAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={FocusEdit} />,
                        },
                    ],
                },
                {
                    path: 'interests',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Interests} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={InterestsAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={InterestsEdit} />,
                        },
                    ],
                },
                {
                    path: 'language',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Languages} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={LanguagesAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={LanguagesEdit} />,
                        },
                    ],
                },
                {
                    path: 'lessons',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Lessons} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={LessonsAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={LessonsEdit} />,
                        },
                    ],
                },
                {
                    path: 'level',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Level} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={LevelAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={LevelEdit} />,
                        },
                    ],
                },
                {
                    path: 'notification-item',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Notification} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={NotificationAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={NotificationEdit} />,
                        },
                    ],
                },
                {
                    path: 'period',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Period} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={PeriodAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={PeriodEdit} />,
                        },
                    ],
                },
                {
                    path: 'permissions',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Permissions} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={PermissionAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={PermissionEdit} />,
                        },
                    ],
                },
                {
                    path: 'plan',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Plan} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={PlanAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={PlanEdit} />,
                        },
                    ],
                },
                {
                    path: 'platform',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Platform} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={PlatformAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={PlatformEdit} />,
                        },
                    ],
                },
                {
                    path: 'promo-code',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={PromoCode} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={PromoCodeAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={PromoCodeEdit} />,
                        },
                    ],
                },
                {
                    path: 'reservations',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Reservations} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={ReservationsAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={ReservationsEdit} />,
                        },
                    ],
                },
                {
                    path: 'slider',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Slider} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={SliderAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={SliderEdit} />,
                        },
                    ],
                },
                {
                    path: 'subscription',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Subscription} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={SubscriptionAdd} />,
                        },
                        // {
                        //     path: 'edit/:id',
                        //     element: <LoadComponent component={SubscriptionEdit} />,
                        // },
                    ],
                },
                {
                    path: 'support-department',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={SupportDepartment} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={SupportDepartmentAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={SupportDepartmentEdit} />,
                        },
                    ],
                },
                {
                    path: 'support-ticket',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={SupportTicket} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={SupportTicketAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={SupportTicketEdit} />,
                        },
                    ],
                },
                {
                    path: 'teaching-style',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={TeachingStyle} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={TeachingStyleAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={TeachingStyleEdit} />,
                        },
                    ],
                },
                {
                    path: 'recordings',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Recordings} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={RecordingsAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={RecordingsEdit} />,
                        },
                    ],
                },
                {
                    path: '/user/tutor',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Tutor} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={TutorAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={TutorEdit} />,
                        },
                    ],
                },
                {
                    path: '/user/student',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Student} />,
                        },
                        {
                            path: 'add',
                            element: <LoadComponent component={StudentAdd} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={StudentEdit} />,
                        },
                    ],
                },
                {
                    path: '/tutor-reviews',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={TutorReviews} />,
                        },
                    ],
                },
                {
                    path: 'gift-duration',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={GiftDuration} />,
                        },
                        {
                            path: 'edit/:id',
                            element: <LoadComponent component={GiftDurationEdit} />,
                        },
                    ],
                },
                {
                    path: 'withdraw',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Widthdraw} />,
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };
