import jwtDecode from 'jwt-decode';
import axios from 'axios';

import config from '../../config';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
    if (token !== null) {
        axios.defaults.withCredentials = true;
        axios.defaults.headers.common['Pragma'] = 'no-cache';
        axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        axios.defaults.headers.common['cookie'] = 'token=' + token + ';';
    } else {
        delete axios.defaults.headers.common['cookie'];
    }
};

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        // console.log(error.response.data.errors.message);
        if (error && error.response && error.response.status === 404) {
            // window.location.href = '/not-found';
        } else if (error && error.response && error.response.status === 403) {
            // console.log(error);
            // window.location.href = '/login';
        } else {
            switch (error.response.status) {
                case 400:
                    message = error.response.data.errors.message;
                    break;
                case 401:
                    message = 'Invalid credentials';
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

const AUTH_SESSION_KEY = 'lesani_dashboard_auth';

const getUserFromCookie = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};
class APICore {
    /**
     * Fetches data from given url
     */
    get = (url: string, params: any) => {
        let response;
        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache',
                Cookie: 'token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MjEzODY4NmJkN2NiYWIzNjE5MGY4MDkiLCJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiQWxpcmV6YSBOYXNzZWgiLCJleHAiOjE2NjQ2MzM2NjguMTI4LCJpYXQiOjE2NTk0NDk2Njh9.fljYJNyqY7y0YcjenYTG95DUG99hy2aYn115fi0D4av_CeCR7zIhxKJ0dpIb97zHKVcN_cMdCy2-VU2tlw0h-n4zJzsol7-3CMEigCakGTIt89KKdgc_-VUhDmehRY6_enz14IDXXSxaexL1W8v8TEv4hS6IIgwcccY0R8fA_w3HDxhlb2iN2wdD9sH8--QCz4LQKyMUb_y4QwQxrp0c4uHsIO5-p06N6Y3mFrXw8xcqhtqEUHJq2wo7wsZ3LfhknuYMQCCsfn7Op6urrLixRCR_7cdjX6DDcHaZvAHFqf4PkdzAGPf5HHeeok6cwAXVQBuqPajB1CucOSqy9WkqIBocsEwDsSjHWgoOoVURawy_rpqRqvxDaUkgtJfqS-BpROjAULK13gQqJghU9BCNtqhH8a5SoLR4YY_q2ovbZlcx7hPsvahZmjnqZsEOXC9sK1BN-09jtkPkIwBGppIW7RT2xO6ng_PAAcosW8oecgd43q9_B_ClGeDK0sFrTXfJakqbiMSUOlROYIcN54N-XRUZj4TCZplrDyeXdkZpNBMW2DFIncT8KBQYgZ33nArEYxjYRUd1UCvCJSMguDJMSnnm63OdJtX4fY8_-QIDhEGw_5C-eD1x4v2yBCS9glNRafk3rroU-i4rZqy8useQuN2wWB4_dktEmPgaOlKbPB4;',
            },
        };
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, config);
        }
        return response;
    };

    getFile = (url: string, params: any) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls: string, params: any) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url: string, data: any) => {
        const config: any = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Pragma': 'no-cache',
                'Cache-Control': 'no-cache',
                'Cookie': 'token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MjEzODY4NmJkN2NiYWIzNjE5MGY4MDkiLCJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiQWxpcmV6YSBOYXNzZWgiLCJleHAiOjE2NjQ2MzM2NjguMTI4LCJpYXQiOjE2NTk0NDk2Njh9.fljYJNyqY7y0YcjenYTG95DUG99hy2aYn115fi0D4av_CeCR7zIhxKJ0dpIb97zHKVcN_cMdCy2-VU2tlw0h-n4zJzsol7-3CMEigCakGTIt89KKdgc_-VUhDmehRY6_enz14IDXXSxaexL1W8v8TEv4hS6IIgwcccY0R8fA_w3HDxhlb2iN2wdD9sH8--QCz4LQKyMUb_y4QwQxrp0c4uHsIO5-p06N6Y3mFrXw8xcqhtqEUHJq2wo7wsZ3LfhknuYMQCCsfn7Op6urrLixRCR_7cdjX6DDcHaZvAHFqf4PkdzAGPf5HHeeok6cwAXVQBuqPajB1CucOSqy9WkqIBocsEwDsSjHWgoOoVURawy_rpqRqvxDaUkgtJfqS-BpROjAULK13gQqJghU9BCNtqhH8a5SoLR4YY_q2ovbZlcx7hPsvahZmjnqZsEOXC9sK1BN-09jtkPkIwBGppIW7RT2xO6ng_PAAcosW8oecgd43q9_B_ClGeDK0sFrTXfJakqbiMSUOlROYIcN54N-XRUZj4TCZplrDyeXdkZpNBMW2DFIncT8KBQYgZ33nArEYxjYRUd1UCvCJSMguDJMSnnm63OdJtX4fY8_-QIDhEGw_5C-eD1x4v2yBCS9glNRafk3rroU-i4rZqy8useQuN2wWB4_dktEmPgaOlKbPB4;',
            },
        };
        return axios.post(url, data, config);
    };

    /**
     * Updates patch data
     */
    updatePatch = (url: string, data: any) => {
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url: string, data: any) => {
        return axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache',
                Cookie: 'token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MjEzODY4NmJkN2NiYWIzNjE5MGY4MDkiLCJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiQWxpcmV6YSBOYXNzZWgiLCJleHAiOjE2NjQ2MzM2NjguMTI4LCJpYXQiOjE2NTk0NDk2Njh9.fljYJNyqY7y0YcjenYTG95DUG99hy2aYn115fi0D4av_CeCR7zIhxKJ0dpIb97zHKVcN_cMdCy2-VU2tlw0h-n4zJzsol7-3CMEigCakGTIt89KKdgc_-VUhDmehRY6_enz14IDXXSxaexL1W8v8TEv4hS6IIgwcccY0R8fA_w3HDxhlb2iN2wdD9sH8--QCz4LQKyMUb_y4QwQxrp0c4uHsIO5-p06N6Y3mFrXw8xcqhtqEUHJq2wo7wsZ3LfhknuYMQCCsfn7Op6urrLixRCR_7cdjX6DDcHaZvAHFqf4PkdzAGPf5HHeeok6cwAXVQBuqPajB1CucOSqy9WkqIBocsEwDsSjHWgoOoVURawy_rpqRqvxDaUkgtJfqS-BpROjAULK13gQqJghU9BCNtqhH8a5SoLR4YY_q2ovbZlcx7hPsvahZmjnqZsEOXC9sK1BN-09jtkPkIwBGppIW7RT2xO6ng_PAAcosW8oecgd43q9_B_ClGeDK0sFrTXfJakqbiMSUOlROYIcN54N-XRUZj4TCZplrDyeXdkZpNBMW2DFIncT8KBQYgZ33nArEYxjYRUd1UCvCJSMguDJMSnnm63OdJtX4fY8_-QIDhEGw_5C-eD1x4v2yBCS9glNRafk3rroU-i4rZqy8useQuN2wWB4_dktEmPgaOlKbPB4;',
            },
        });
    };

    /**
     * Deletes data
     */
    delete = (url: string) => {
        return axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache',
                Cookie: 'token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MjEzODY4NmJkN2NiYWIzNjE5MGY4MDkiLCJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiQWxpcmV6YSBOYXNzZWgiLCJleHAiOjE2NjQ2MzM2NjguMTI4LCJpYXQiOjE2NTk0NDk2Njh9.fljYJNyqY7y0YcjenYTG95DUG99hy2aYn115fi0D4av_CeCR7zIhxKJ0dpIb97zHKVcN_cMdCy2-VU2tlw0h-n4zJzsol7-3CMEigCakGTIt89KKdgc_-VUhDmehRY6_enz14IDXXSxaexL1W8v8TEv4hS6IIgwcccY0R8fA_w3HDxhlb2iN2wdD9sH8--QCz4LQKyMUb_y4QwQxrp0c4uHsIO5-p06N6Y3mFrXw8xcqhtqEUHJq2wo7wsZ3LfhknuYMQCCsfn7Op6urrLixRCR_7cdjX6DDcHaZvAHFqf4PkdzAGPf5HHeeok6cwAXVQBuqPajB1CucOSqy9WkqIBocsEwDsSjHWgoOoVURawy_rpqRqvxDaUkgtJfqS-BpROjAULK13gQqJghU9BCNtqhH8a5SoLR4YY_q2ovbZlcx7hPsvahZmjnqZsEOXC9sK1BN-09jtkPkIwBGppIW7RT2xO6ng_PAAcosW8oecgd43q9_B_ClGeDK0sFrTXfJakqbiMSUOlROYIcN54N-XRUZj4TCZplrDyeXdkZpNBMW2DFIncT8KBQYgZ33nArEYxjYRUd1UCvCJSMguDJMSnnm63OdJtX4fY8_-QIDhEGw_5C-eD1x4v2yBCS9glNRafk3rroU-i4rZqy8useQuN2wWB4_dktEmPgaOlKbPB4;',
            },
        });
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config: any = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();

        if (!user) {
            return false;
        }
        const decoded: any = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        } else {
            return true;
        }
    };

    setLoggedInUser = (session: any) => {
        if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            sessionStorage.removeItem(AUTH_SESSION_KEY);
        }
    };
    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromCookie();
    };

    setUserInSession = (modifiedUser: any) => {
        let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, ...user, ...modifiedUser });
        }
    };
}

/*
Check if token available in session
*/
let user = getUserFromCookie();
if (user) {
    const { token } = user;
    if (token) {
        setAuthorization(token);
    }
}

export { APICore, setAuthorization };
