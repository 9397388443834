import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationAr from './locales/ar/translation.json';

//translations
const resources = {
    ar: {
        translation: translationAr,
    },
};



i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });
let language = i18n.language;
if (language==='ar') {
    document.body.classList.add('rtl');
    document.body.classList.remove('ltr');
    document.body.style.direction = 'rtl';
}else{
    document.body.classList.add('ltr');
    document.body.classList.remove('rtl');
    document.body.style.direction = 'ltr';
}


export default i18n;
